import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <img src="./retina-logo4.png" />
        <p>
           <code>Welcome to Thiru Hosptal</code> 
        </p>
        <a
          className="App-link"
          href="https://thiruhospital.in"
          target="_blank"
          rel="noopener noreferrer"
        >
          Thiru Hosptal
        </a>
      </header>
    </div>
  );
}

export default App;
